import React from "react";

const PartnerCard = ({ value, title }) => {
  return (
    <div className="partner_card">
      <div className="partner_card__value">{value}</div>
      <div className="partner_card__title">{title}</div>
    </div>
  );
};

export default PartnerCard;
