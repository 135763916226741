import { gql } from "@apollo/client";

export const GetActiveArtikel = gql`
  {
    GetActiveArtikel {
      ListArtikel {
        id
        judulArtikel
        createDate
        createBy
        updateDate
        updateBy
        fotoArtikel
        kategoriArtikel
        __typename
      }
      response {
        status
        errMsg
        __typename
      }
      __typename
    }
  }
`;

export const GetSingleArtikel = gql`
  query GetSingleArtikel($id: ID) {
    GetSingleArtikel(id: $id) {
      ListArtikel {
        id
        isiArtikel
        createDate
        createBy
        updateDate
        updateBy
        channelId
        orgId
        fotoArtikel
        flagActive
        kategoriArtikel
        kumpulanFotoArtikel
        kodeKategoriProduk
        judulArtikel
        __typename
      }
      response {
        status
        errMsg
        __typename
      }
      __typename
    }
  }
`;
