import React, { useEffect } from 'react';
import { term2x } from '../../components/assets';

const TermCondition = ()=>{

    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:'0'
        })
    },[])
    return (
        <section className="term-condition">
            <div className="term-condition__header">
                <img  src={term2x} alt="term condition" className="term-condition__icon" />
                <h1 className="term-condition__title">Terms and Conditions</h1>
                <p className="term-condition__warn">PLEASE READ THESE LEGAL NOTICES CAREFULLY BEFORE USING www.emos.id</p>
            </div>

            <div className="term-condition__text-container">
                <p className="term-condition__text">
                PT Emos Global Digital. (the “<span className="term-condition__text-bold">EGD</span>”) provides www.emos.id (the “Site”) subject to your compliance with these Legal Notices (the “Notices”). By using the Site you agree to be bound by this Notices. If you do not agree to its Notices, please do not use the Site.
                </p>
                <br/>
                <br/>
                <h2 className="term-condition__text-bold">contents</h2>
                <p className="term-condition__text">The information, documents and some related graphics published in the Site (the “Information”) are the sole property of <span className="term-condition__text-bold">EGD</span>. Permission to use the Information is granted, provided that:</p>
                <br/>
                <br/>
                <p className="term-condition__text">(1) The above copyright notice appears on all copies;</p>
                <p className="term-condition__text">(2) Use of the Information is for informational and non-commercial or personal use only;</p>
                <p className="term-condition__text">(3) The Information is not modified in any way; and</p>
                <p className="term-condition__text">(4) No graphics available from this Site are used separate from accompanying text.</p>
                <br/>
                <br/>
                <p className="term-condition__text">Except as permitted above, no license or right, express or implied, is granted to any person under any patient, trademark or other proprietary right of <span className="term-condition__text-bold">EGD</span>.</p>
                <br/>
                <br/>
                <p className="term-condition__text">Each person assumes full responsibility and all risk arising from use of this Site. The Information is presented “AS IS” and may include technical inaccuracies or typographical errors. <span className="term-condition__text-bold">EGD</span> reserves the right to make addition, deletions or modifications to the Information at any time without any prior notification.</p>
                <br/>
                <br/>
                <h2 className="term-condition__text-bold">NO MEDICAL ADVICE</h2>
                <p className="term-condition__text">
                <span className="term-condition__text-bold">EGD</span> does not practice medicine or provide medical services of any kind on or through the Site. The Informations are for informational purposes only and are not substitute for professional medical advice. You should never disregard or delay seeking medical advice because of something you see in the Site, you should always talk to a physician or other qualified health professional concerning your medical condition.
                </p>
                <br/>
                <br/>
                <h2 className="term-condition__text-bold">PRODUCTS</h2>
                <p className="term-condition__text">
                Unless otherwise noted, product names, descriptions and labeling are of Indonesia origin, Product may not be available in all countries or may be available under a different name, in different strengths, or for different indications. Many of the products listed are available only by prescription through your local healthcare professional.
                </p>
                <br/>
                <br/>
                <p className="term-condition__text">Those who choose to access the Site do so on their own initiative and at their own risk, and are responsible for complying with local regulations.</p>
                <br/> <br/>
                <h2 className="term-condition__text-bold">LINKS TO OTHER WEBSITES</h2>
                <p className="term-condition__text">The Site may contain links to other websites or resources. <span className="term-condition__text-bold">EGD</span> neither control nor endorse such other websites, nor has reviewed or approved any content on other websites. You expressly agree that your use of other websites is at your sole risk.</p>
                <br/> <br/>
                <h2 className="term-condition__text-bold">DISCLAIMER AND LIMITATION OF LIABILITY</h2>
                <p className="term-condition__text"><span className="term-condition__text-bold">EGD</span> makes no representations or warranties of any kind or nature with respect to the information. <span className="term-condition__text-bold">EGD</span> hereby disclaims all representations and warranties whether express or implied, created by law, contract or otherwise, including, without limitation, any warranties of merchantability, fitness for a particular purpose, title or non-infringement. In no event shall <span className="term-condition__text-bold">EGD</span> be liable for any damages of any kind or nature, including, without limitation, direct, indirect, special (including loss or profit) consequential or incidental damages arising from or in connection with the existence or use of this site and/or the information, regardless of whether <span className="term-condition__text-bold">EGD</span> has been advised as to the possibility of such damages.</p>
                <br/> <br/>
                <h2 className="term-condition__text-bold">APPLICABLE LAW</h2>
                <p className="term-condition__text">This Site is governed by the laws of the Republic of Indonesia, without regard to principles of conflicts of law.</p>
                <br/> <br/>
                <h2 className="term-condition__text-bold">INFORMATION</h2>
                <p className="term-condition__text">For further information, please send an e-mail through http://www.emos.id/en/contact/.</p>
                <br/><br/>
                <h2 className="term-condition__text-bold">PRIVACY NOTICE</h2>
                <p className="term-condition__text">This notice describes our privacy policy. By visiting www.emos.id (the “Site”), you are accepting the practices described in this Privacy Notice.</p>
            </div>
        </section>
    )
}

export default TermCondition;