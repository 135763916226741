import React from "react";

// Loader mode: full | content

const Loader = ({ mode = "full" }) => {
  return (
    <div
      className={
        mode === "content"
          ? "loading-container-loader"
          : "loading-container-loader-full"
      }
    >
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Loader;
