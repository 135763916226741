import { gql } from "@apollo/client";

export const insertNewKontak = gql`
  mutation InsertNewKontak($input: InputNewKontakData!) {
    InsertNewKontak(input: $input) {
      response {
        errMsg
        status
      }
    }
  }
`;
