import React from "react";
import { NavLink } from "react-router-dom";
import { partner2x, layer2_2x } from "../../components/assets";
import PartnerList from "../../components/partner-list";
import PartnerCard from "../../components/partner-card";

const principal = [
  "PT Kalbe Farma",
  "PT Enseval Medika Prima",
  "PT Hexpharm Jaya",
  "PT Osaki Medical Indonesia",
  "PT Interbat",
  "PT AstraZeneca Indonesia",
  "PT Sakafarma Laboratories",
  "PT Kalbe Blackmores Nutrition",
  "PT Kalbe Beverage",
  "PT Hale International",
  "PT Nestle Indonesia",
  "PT Motasa Indonesia",
  "PT Beiersdorf Indonesia",
  "PT Bintang Toedjoe",
  "PT Kara Santan Pertama",
];

const eCommerce = [
  "PT Tumbakmas Niaga Sakti",
  "PT Sinergi Multi Distrindo",
  "PT Taisho Pharmaceutical Indonesia",
  "PT Perfetti Va Melle Indonesia",
  "PT Nutrifood Indonesia",
  "PT Asia Sejahtera Pharmaceutical",
];

const service = [
  "UD. Jaya Subur",
  "PT Kreasi Wira Agung",
  "PT Pangan Argo Lestari",
  "K33 Distribusi",
];

const Partners = () => {
  return (
    <div className="section">
      <section className="partners">
        <div className="partners__header">
          <img className="partners__icon" src={partner2x} alt="partner emos" />
          <h3 className="text-title partners__title">
            Our Key Business Partners
          </h3>
          <p className="partners__desc">
            Building a digital ecosystem that provides quality health products
            and services with excellent service for consumers
          </p>
        </div>

        {/* <div className="partners__content">
          <PartnerList title="Principal" list={principal} />
          <PartnerList title="e-Commerce B2B Partnership" list={eCommerce} />
          <PartnerList title="EMOS as a Service" list={service} />
        </div> */}

        <div className="partners__cards">
          <PartnerCard value="280+" title="Principals" />
          <PartnerCard value="200+" title="Distributors" />
          <PartnerCard value="140k+" title="Customers" />
        </div>

        <NavLink to="/business" className="partners__btn text-title">
          Join Us
        </NavLink>
      </section>

      <img src={layer2_2x} alt="layer" className="partners__layer" />
    </div>
  );
};

export default Partners;
