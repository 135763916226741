import React, { useState } from 'react'
import Autocomplete from '../../components/autocomplete';
  import { faLocationPin, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import CareerItem from './CareerItem';
const Career = () => {
    const [searchLokasi, setSearchLokasi] =  useState()
    const [lokasi, setLokasi] = useState('')
    return ( 
        <div className="karir">
            <div className="karir__top">
                <div className="karir__text">Temukan Karir Impianmu</div>
                <div className="karir__text-sm">Mulai berkarir dan temukan tujuanmu bersama EMOS</div>
            </div>
            <div className="karir__option-container">
                <div className="karir__option">
                    <Autocomplete icon={faLocationPin} placeholder="Semua Lokasi"/>
                    <Autocomplete icon={faUser} placeholder="Semua Jenis Lamaran" />
                    <Autocomplete icon={faUser} placeholder="Semua Pengalaman" />
                    <Autocomplete icon={faSearch} placeholder="Nama Posisi" />
                    <button className="karir__cari">Cari</button>
                </div>
            </div>
            <div className="karir__list">
                    <CareerItem />
                    <CareerItem />
                    <CareerItem />
                    <CareerItem />
                    <CareerItem />
                    <CareerItem />
            </div>
            <div className="karir__btm">
                <button className="karir__cari">Lihat Lebih</button>
            </div>
        </div>
     );
}
 
export default Career;