import React, { useState } from "react";
import MessageForm from "../../components/message-form";
import RespondCard from "../../components/respond-card";

const Contact = () => {
  const [respond, setRespond] = useState("");
  const [loading, setLoading] = useState(false);

  const content = respond ? (
    <RespondCard />
  ) : (
    <div style={{ marginTop: "30%", marginBottom: "30%" }}>
      <MessageForm
        setRespond={(val) => setRespond(val)}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );

  console.log(respond ? true : false);
  return (
    <div className="section">
      <section className="contact">
        <div className="contact__content">{content}</div>
      </section>
    </div>
  );
};

export default Contact;
