import React, { useState } from "react";
import { group237_2x, contact2x, mail2x, location2x } from "../assets";
import Loader from "../loader";
import apiBisnis from "../../api/apiBisnis";
import { insertNewKontak } from "../../graphql/contact/mutations";
import {
  MailIcon,
  MapPinIcon,
  PhoneIcon,
  MessageCircleIcon,
} from "lucide-react";
const MessageForm = ({ setRespond, loading, setLoading }) => {
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [pesan, setPesan] = useState("");
  //   const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await apiBisnis.mutate({
        mutation: insertNewKontak,
        variables: {
          input: {
            nama: nama,
            message: pesan,
            creator: email,
            email: email,
          },
        },
      });
      const { status, errMsg } = res.data.InsertNewKontak.response;
      if (status !== "0") {
        throw new Error(errMsg);
      }
      setRespond(true);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="message">
      {loading && <Loader />}
      <div className="message__info">
        <h1 className="message__header">Contact Us</h1>
        <p className="message__description">
          Do you have any question <br /> or need further information?
        </p>
        <div className="message__contacts">
          <PhoneIcon className="message__icon" size={28} color="#65BD8C" />
          <div className="message__text">
            <p>021 460 97 20</p>
          </div>
        </div>

        <div
          className="message__contacts"
          onClick={() => {
            window.open(
              "https://wa.me/6285710412340",
              "_blank",
              "noopener noreferrer"
            );
          }}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <MessageCircleIcon
              className="message__icon"
              size={30}
              color="#65BD8C"
            />
            <PhoneIcon
              className="message__icon"
              size={13}
              strokeWidth={3.5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -55%)",
                color: "#65BD8C",
              }}
            />
          </div>
          <div className="message__text">
            <p>+62 8571-0412-340</p>
          </div>
        </div>

        <div className="message__contacts">          
          <MailIcon className="message__icon" size={28} color="#65BD8C" />
          <div className="message__text">
            <p>support@emos.id</p>
          </div>
        </div>

        <div className="message__contacts">
          <MapPinIcon className="message__icon" size={28} color="#65BD8C" />
          <div className="message__text">
            <p>PT Emos Global Digital</p>
            <p>Jl.Pulo Lentut No 12</p>
            <p>Kawasan Industri Pulogadung</p>
            <p>Jakarta Timur - 13920</p>
          </div>
        </div>        
      </div>

      <form className="message__form" onSubmit={onSubmit}>
        <div className="message__form--extension"></div>
        <input
          type="text"
          className="message__form__input"
          placeholder="Name"
          required
          onChange={(e) => setNama(e.target.value)}
          value={nama}
        />
        <input
          type="email"
          className="message__form__input"
          placeholder="Email"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <textarea
          type="text"
          className="message__form__multirows"
          placeholder="Message"
          spellCheck="false"
          required
          onChange={(e) => setPesan(e.target.value)}
          value={pesan}
        />
        <button className="message__form__btn" type="submit">
          Send &nbsp; <span className="message__form__btn--arr">&rarr;</span>
        </button>
      </form>

      <img className="message__form-image" src={group237_2x} alt="group" />
    </div>
  );
};

export default MessageForm;
