import React from 'react';
import { NavLink } from 'react-router-dom';
import { services2x, logo, layer2_2x, playstore } from '../../components/assets';

const Services = ()=>{
    return(
        <section className="services">
                <img src={services2x} alt="EMOS service" className="services__background" />
                <img src={layer2_2x} alt="LAYER" className="services__layer" />
                <h1 className="services__title-top text-title">Our Services</h1>
                <div className="services__1">
                    <h1 className="services__title text-title">For Business</h1>
                    <p className="services__text">
                    Providing EMOS as A Service to make your products available in EMOS Market place. So you can connect with your customer through EMOS.</p>
                </div>
                <div className="services__2">
                    <h1 className="services__title text-title">For Customer</h1>
                    <p className="services__text">
                    One application suitable for all you need. We give you access to your favourite products 24 hour a day and 7 days a week. It’s basically anytime and anywhere.
                    </p>
                </div>  
                <NavLink to="/business" className="services__btn text-title">Join Us</NavLink>
                <a href="https://play.google.com/store/apps/details?id=emos.egd.emos_marketplace" rel="noopener noreferrer" target="_blank" style={{display:"flex",justifyContent:"center"}} ><img src={playstore} alt="" className="services__playstore"/></a>
            </section>
    )
}

export default Services;