import React from 'react';
import { NavLink } from 'react-router-dom';
import { layer2_2x, phone, playstore, wave } from '../../components/assets';

const Home = ()=>{
    return (
        <div className="section">
            <section className="home">
                <div className="home__left">
                    <h1 className="home__number-container text-title">
                        <span className="home__icon-number">#</span>
                        <span className="home__number">1</span>
                    </h1>
                    <p className="home__text">
                        Number One Business to Business platform <br/> 
                        specialized in pharmaceutical <br/>
                        and consumer health products.<br/>
                        Make your business more effective, <br/>
                        efficient, and profitable with EMOS
                    </p>
                    <NavLink className="home__button" to="about">Read More</NavLink>
                </div>
                <a href="https://play.google.com/store/apps/details?id=emos.egd.emos_marketplace" rel="noopener noreferrer" target="_blank" className="home__download" ><div className="home__invisible"></div></a>
                
                
                
            </section>
        </div>
    )
}

export default Home;