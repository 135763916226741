import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
  import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
const Autocomplete = ({icon, placeholder}) => {
    return ( 
        <div className='autocomplete'>
            <FontAwesomeIcon icon={icon} className="autocomplete__icon" />
            <input className='autocomplete__result' placeholder={placeholder} />
             <FontAwesomeIcon icon={faAngleDown} className="autocomplete__icon" />
        </div>
     );
}
 
export default Autocomplete;