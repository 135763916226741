import React, { useEffect, useState } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Navigation from "../navigation";
import Home from "../../pages/home";
import Services from "../../pages/services";
import About from "../../pages/about";
import Contact from "../../pages/contact";
import Footer from "../footer";
import Partners from "../../pages/partners";
import TermCondition from "../../pages/term-condition";
import News from "../../pages/news";
import Business from "../../pages/business";
import Career from "../../pages/career";
import CareerDetail from "../../pages/career/CareerDetail";
import Consent from "../consent";
import useG4APageView from "../../hooks/useGA4PageView";

const RouterComponent = () => {
  useG4APageView();

  return (
    <div>
      <Navigation transparent={true} />
      <Switch>
        <Route path="/services" exact component={Services} />
        <Route path="/partners" exact component={Partners} />
        <Route path="/news" exact component={News} />
        <Route path="/business" exact component={Business} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/term-condition" exact component={TermCondition} />
        <Route path="/career" exact component={Career} />
        <Route path="/career/:id" exact component={CareerDetail} />
        <Route path="/" exact component={Home} />
      </Switch>
      <Footer />
      <Consent />
    </div>
  );
};

export default RouterComponent;
