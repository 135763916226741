// LOGO
export const logo2x = require('../assets/NEW LOGO EMOS with tagline.png');

//NEW LOGO 
export const newLogo = require('../assets/NEW LOGO EGD only.png');

//SMARTPHONE
export const smartphone2x = require('../assets/Asset-1.png');
export const phone = require('../assets/Mockup 2.png');
export const phone2 = require('../assets/Group 6480.png');

//SERVICES
export const services2x = require('../assets/Group 6479.png');

//ABOUT
export const about2x = require('../assets/14.png');

//LAYER
export const layer2_2x = require('../assets/Layer 2@2x.png');

//MISSION
export const mission2x  = require('../assets/mission.png');

//VISION
export const vision2x  = require('../assets/vision.png');

//GROUP
export const group237_2x = require('../assets/Group 237.png');

export const group75_2x = require('../assets/Group 75.png');

//CONTACT
export const contact2x = require ('../assets/Contact.png');
export const whatsapp = require('../assets/whatsapp.png');

//MAIL
export const mail2x = require('../assets/Layer 4.png');

//LOC
export const location2x = require('../assets/gps.png');

//PARTNER ICON
export const partner2x = require('../assets/Filledoutline.png');

//terms ICON
export const term2x = require('../assets/Filled outline.png');

//fonts
export const keepCalm = require('../fonts/KeepCalm-Medium.ttf');
export const SFUI = require('../fonts/SFUIText-Regular.ttf');

//download playstore
export const playstore = require('../assets/Group 6465.png');

//news
export const news = require('../assets/news.png');

//liquid
export const liquid = require('../assets/liquid.png');

//join logo

export const join = require('../assets/join.PNG');

//kara news
export const karaThumbnail = require('../assets/kara thumbnail.PNG');
export const karaImg1 = require('../assets/MOSLY EMOS KARA.jpg');
export const karaImg2 = require('../assets/News EGD 2.jpeg');

//wave
export const wave = require('../assets/wave.png');
export const homeBackground = require('../assets/homeBackground.png');

//ISO Logo
export const isoLogo27001 = require('../assets/iso-27001-logo.png');

//PSE Logo
export const pseLogo = require('../assets/PSE.png');

//BSI Logo
export const bsiLogoWhite = require('../assets/bsi-isoiec-27001-white.jpg');
export const bsiLogoTransparent = require('../assets/bsi-isoiec-27001-transparent.png');