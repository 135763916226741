import React, { useEffect } from 'react';
import { group75_2x, layer2_2x } from '../assets';
import { NavLink } from 'react-router-dom';

const RespondCard = ()=>{
    useEffect(()=>{
        window.scroll({
            behavior:'smooth',
            top:'0'
        },[])
    })
    return (
        <>
        <div className="card">
            <div className="card__content">
                <h1 className="card__header">terima kasih</h1>
                <p className="card__text">Terima kasih telah menghubungi kami, <br/> Kami akan segera membalas pesan dari Anda</p>
                <NavLink className="card__btn" to="/">Kembali ke Beranda</NavLink>
                
            </div>
            <img className="card__image" alt="group" src={group75_2x} />
            {/* <img className="card__layer" alt="layer" src={layer2_2x} /> */}
        </div>
        <img className="card__layer" alt="layer" src={layer2_2x} />
        </>
    )
}

export default RespondCard;