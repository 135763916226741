import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { logo2x } from "../assets";
import BackgroundFilter from "../background-filter";

const Navigation = () => {
  let location = useLocation().pathname;
  let navClass = "navigation";
  if (location && (location === "/" || location === "/contact")) {
    navClass = "navigation navigation--transparent";
  }

  const [smallNav, setSmallNav] = useState({
    activeNav: false,
  });

  useEffect(() => {
    setSmallNav({ activeNav: false });
  }, [location]);

  return (
    <div className={navClass}>
      <div className="navigation--logo-container">
        <div className="concat-logo">
          <img className="concat-logo__logo" src={logo2x} alt="emos logo" />
          <p className="concat-logo__logo-text">pt emos global digital</p>
        </div>
      </div>
      <div
        className="navigation--btn"
        onClick={() => setSmallNav({ activeNav: !smallNav.activeNav })}
      >
        <div
          className={
            smallNav.activeNav
              ? "navigation--small navigation--active"
              : "navigation--small"
          }
        ></div>
      </div>

      <div className="navigation--container">
        <NavLink
          className="navigation__menu "
          exact
          activeClassName="navigation__menu--active"
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/services"
        >
          Services
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/partners"
        >
          Partners
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/business"
        >
          Business
        </NavLink>
        {/* <NavLink className="navigation__menu " activeClassName="navigation__menu--active" to="/career">Career</NavLink> */}
        {/* <a
          href="http://www.emos.id/article/"
          target="_blank"
          rel="noopener noreferrer"
          className="navigation__menu "
          to="/news"
        >
          News
        </a> */}
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/news"
        >
          News
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/about"
        >
          About
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/contact"
        >
          Contact
        </NavLink>
      </div>

      {smallNav.activeNav && (
        <BackgroundFilter
          handleClose={() => setSmallNav({ activeNav: !smallNav.activeNav })}
        />
      )}
      <div
        className={
          smallNav.activeNav
            ? "navigation--menus navigation--active"
            : "navigation--menus"
        }
      >
        <NavLink
          className="navigation__menu "
          exact
          activeClassName="navigation__menu--active"
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/services"
        >
          Services
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/partners"
        >
          Partners
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/business"
        >
          Business
        </NavLink>
        {/* <NavLink className="navigation__menu " activeClassName="navigation__menu--active" to="/career">Career</NavLink>
                <a href='http://www.emos.id/article/' target='_blank' rel="noopener noreferrer" className="navigation__menu " to="/news">News</a> */}
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/news"
        >
          News
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/about"
        >
          About
        </NavLink>
        <NavLink
          className="navigation__menu "
          activeClassName="navigation__menu--active"
          to="/contact"
        >
          Contact
        </NavLink>
      </div>
    </div>
  );
};

export default Navigation;
