import React from 'react'
  import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CareerItem from './CareerItem';
import ModalDaftar from './ModalDaftar';

const CareerDetail = () => {
    return <>
    <div className='karir'>
        <div className="karir__detail">
            <div className="karir__text">Relationship Offer</div>
            <div className="karir__text-sm">Fresh Graduate <FontAwesomeIcon icon={faCircle} /> Program Trainee </div>
            <div className="karir__btns">
                <button className='btn-daftar'>DAFTAR</button>
                <button className='btn-daftar btn-daftar--light ml-2'>BAGIKAN</button>
            </div>
        </div>
        <div className="karir-detail">
            <div className="left">
                <div className="simple-card">
                    <div className="title">Tentang Pekerjaan</div>
                    <div className="detail">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure perspiciatis sunt deserunt. Nostrum distinctio modi saepe voluptatem dolorum! Autem quidem nihil placeat dolor pariatur facilis tenetur reiciendis porro excepturi. Accusantium aspernatur doloribus cupiditate ea mollitia similique, aut repudiandae eligendi temporibus facilis quam libero repellat deserunt quod excepturi laboriosam sequi quo earum? Voluptate sunt veritatis praesentium nisi. Distinctio eligendi eum facere laudantium consequatur eius et exercitationem repellendus impedit dolores ex libero accusantium quidem animi aut illo mollitia, cupiditate debitis excepturi rem nostrum adipisci id voluptatem. Facere delectus illo non qui voluptatibus doloremque nostrum, quaerat corporis, aut tenetur dolorum fugit excepturi! Delectus?</div>
                </div>

                 <div className="simple-card mt-2">
                    <div className="title">Persyaratan</div>
                    <div className="detail">
                        <ul>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nesciunt?</li>
                        </ul>
                    </div>
                </div>

                <div className="simple-card mt-2">
                    <div className="title">Tahapan Seleksi</div>
                    <div className="detail">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Alias, eaque. Incidunt rerum id amet maxime sit voluptas aut? Aliquam, aliquid?</div>
                </div>

                <div className="simple-card mt-2">
                    <div className="title">Informasi Tambahan</div>
                    <div className="detail">Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus incidunt necessitatibus vel aut hic eum molestiae blanditiis adipisci error maiores modi ipsa omnis sint, at, voluptate, id magnam nostrum illum exercitationem unde nobis dignissimos eos! Nihil voluptatibus facere eligendi dolore.</div>
                </div>
            </div>
            <div className="right">
                <div className="simple-card ">
                    <div className="title">Tanggal Pendaftaran</div>
                    <div className="detail">01 Desember 2021 - 31 Desember 2022</div>
                </div>

                <div className="simple-card mt-2">
                    <div className="title">Batas Usia</div>
                    <div className="detail">17 - 24 Tahun</div>
                </div>

                <div className="simple-card mt-2">
                    <div className="title">Lokasi Tes</div>
                    <div className="detail">
                        <ul>
                            <li>Bandung</li>
                            <li>Jakarta</li>
                            <li>Depok</li>
                            <li>Bekasi</li>
                            <li>Papua</li>
                            <li>BSD</li>
                            <li>Kalimantan</li>
                            <li>Jabodetabek</li>
                            <li>Bengkulu</li>
                            <li>Medan</li>
                        </ul>
                    </div>
                </div>

                <div className="simple-card mt-2">
                    <div className="title">Lokasi Penempatan</div>
                    <div className="detail">
                        <ul>
                            <li>Bandung</li>
                            <li>Jakarta</li>
                            <li>Depok</li>
                            <li>Bekasi</li>
                            <li>Papua</li>
                            <li>BSD</li>
                            <li>Kalimantan</li>
                            <li>Jabodetabek</li>
                            <li>Bengkulu</li>
                            <li>Medan</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-title-2">Karir Lainnya</div>
         <div className="karir__list">
            <CareerItem />
            <CareerItem />
            <CareerItem />
        </div>
    </div>

    {/* <ModalDaftar /> */}
    </>
}
export default CareerDetail;