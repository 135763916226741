import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from "../../constants/ga4";
import { ACCEPT_COOKIE } from "../../constants/local";

const Consent = () => {
  const [isDisplay, setIsDisplay] = useState(false);

  useEffect(() => {
    const check = localStorage.getItem(ACCEPT_COOKIE);
    if (check !== "true") setIsDisplay(true);
    else setIsDisplay(false);
  }, []);

  const handleClick = () => {
    // ReactGA.initialize(GA_MEASUREMENT_ID);
    localStorage.setItem(ACCEPT_COOKIE, "true");
    setIsDisplay(false);
  };

  return (
    <div
      className="consent__container"
      style={{
        visibility: isDisplay ? "visible" : "hidden",
        opacity: isDisplay ? "1" : "0",
        transition: isDisplay ? "visibility 0s, opacity 0.5s linear" : "",
      }}
    >
      <p className="consent__header">Consent</p>
      <p className="consent__text">
        We use cookies for content personalization, social media, analytics and
        performance needs.
      </p>
      <div className="consent__button-container">
        <button className="consent__button" onClick={handleClick}>
          Agree
        </button>
      </div>
    </div>
  );
};

export default Consent;
