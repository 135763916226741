import React, { useEffect, useState } from "react";
import {
  contact2x,
  join,
  layer2_2x,
  location2x,
  mail2x,
  whatsapp,
} from "../../components/assets";
import RespondCard from "../../components/respond-card";
import apiBisnis from "../../api/apiBisnis";
import { insertNewBisnisPartner } from "../../graphql/bisnis/mutations";
import Loader from "../../components/loader";
import {
  MailIcon,
  MapPinIcon,
  PhoneIcon,
  MessageCircleIcon,
} from "lucide-react";

const BusinessForm = ({ controlSubmitted, loading, setLoading }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [outlet, setOutlet] = useState("");
  const [outletAddr, setOutletAddr] = useState("");
  const [outletCheckbox, setOutletCheckbox] = useState(false);
  const [principalCheckbox, setPrincipalCheckbox] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await apiBisnis.mutate({
        mutation: insertNewBisnisPartner,
        variables: {
          input: {
            nama: name,
            creator: email,
            email: email,
            phonenumber: phone,
            tipe: outletCheckbox ? "outlet" : "principal",
            namaOutlet: outlet,
            outletAddress: outletAddr,
          },
        },
      });
      const { status, errMsg } = res.data.InsertNewBisnisPartner.response;
      if (status !== "0") {
        throw new Error(errMsg);
      }
      controlSubmitted();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="business">
      <div className="business__header">
        <img className="business__icon" src={join} alt="partner emos" />
        <h3 className="text-title business__title">Join with us</h3>
        <p className="business__desc">
          Let's join us and grow your business. <br />
          Join as an outlet partner of EMOS or as a product owner who wants his
          products to be distributed to more than 2,000 EMOS partners spread
          across the country.
        </p>
      </div>
      <div className="message business__form">
        <div className="message__info">
          <h1 className="message__header">Contact Us</h1>
          <p className="message__description">
            Do you have any question <br /> or need further information?
          </p>
          <div className="message__contacts">
            <PhoneIcon className="message__icon" size={28} color="#65BD8C" />
            <div className="message__text">
              <p>021 460 97 20</p>
            </div>
          </div>

          <div
            className="message__contacts"
            onClick={() => {
              window.open(
                "https://wa.me/6285710412340",
                "_blank",
                "noopener noreferrer"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <MessageCircleIcon
                className="message__icon"
                size={30}
                color="#65BD8C"
              />
              <PhoneIcon
                className="message__icon"
                size={13}
                strokeWidth={3.5}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -55%)",
                  color: "#65BD8C",
                }}
              />
            </div>
            <div className="message__text">
              <p>+62 8571-0412-340</p>
            </div>
          </div>

          <div className="message__contacts">
            {/* <img className="message__icon" src={mail2x} alt="mail" /> */}
            <MailIcon className="message__icon" size={28} color="#65BD8C" />
            <div className="message__text">
              <p>support@emos.id</p>
            </div>
          </div>

          <div className="message__contacts">
            <MapPinIcon className="message__icon" size={28} color="#65BD8C" />
            <div className="message__text">
              <p>PT Emos Global Digital</p>
              <p>Jl.Pulo Lentut No 12</p>
              <p>Kawasan Industri Pulogadung</p>
              <p>Jakarta Timur - 13920</p>
            </div>
          </div>
        </div>

        <form
          className="message__form"
          style={{ paddingTop: "4rem" }}
          onSubmit={(e) => onSubmit(e)}
        >
          <div className="message__form--extension"></div>
          <input
            type="text"
            className="message__form__input"
            placeholder="Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            className="message__form__input"
            placeholder="Email Address"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="number"
            className="message__form__input"
            placeholder="Phone Number"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div className="business__checkboxs">
            <div className="check-group">
              <input
                type="checkbox"
                id="outlet"
                checked={outletCheckbox}
                onChange={(e) => setOutletCheckbox(e.target.checked)}
              />
              <span></span>
              <label htmlFor="outlet">Outlet</label>
            </div>

            <div className="check-group">
              <input
                type="checkbox"
                id="principal"
                checked={principalCheckbox}
                onChange={(e) => setPrincipalCheckbox(e.target.checked)}
              />
              <span></span>
              <label htmlFor="principal">Principal</label>
            </div>
          </div>
          <input
            type="text"
            className="message__form__input"
            placeholder="Outlet Name"
            value={outlet}
            onChange={(e) => setOutlet(e.target.value)}
            required
          />
          <textarea
            type="text"
            className="message__form__input"
            placeholder="Outlet Address"
            value={outletAddr}
            onChange={(e) => setOutletAddr(e.target.value)}
            required
          ></textarea>
          <button className="message__form__btn" type="submit">
            Send &nbsp; <span className="message__form__btn--arr">&rarr;</span>
          </button>
        </form>
      </div>
      <img src={layer2_2x} alt="" className="business__layer" />
    </div>
  );
};

const BusinessResponse = () => {
  return (
    <div className="contact">
      <div className="contact__content">
        <RespondCard />
      </div>
    </div>
  );
};

const Business = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // window.scroll({
    //   behavior: "smooth",
    //   top: "0",
    // });
  });

  const controlSubmitted = () => {
    setIsSubmitted(!isSubmitted);
  };
  return (
    <>
      {loading && <Loader />}
      {isSubmitted ? (
        <BusinessResponse />
      ) : (
        <BusinessForm
          controlSubmitted={controlSubmitted}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default Business;
