import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ReactGA4 from "react-ga4";
import { GA4_EVENT_PAGE_VIEW } from "../constants/ga4";

const useG4APageView = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA4.event(GA4_EVENT_PAGE_VIEW, {
      page_path: `${location.pathname}${location.search}${location.hash}`,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};

export default useG4APageView;
