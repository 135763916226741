import { gql } from "@apollo/client";

export const insertNewBisnisPartner = gql`
  mutation InsertNewBisnisPartner($input: InputNewBisnisData!) {
    InsertNewBisnisPartner(input: $input) {
      isSucceed
      response {
        errMsg
        status
      }
    }
  }
`;
