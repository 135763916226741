import React, { useEffect, useState } from "react";
import Spinner from "./components/spinner/Spinner";
import {
  about2x,
  contact2x,
  group237_2x,
  group75_2x,
  homeBackground,
  karaImg1,
  karaImg2,
  karaThumbnail,
  keepCalm,
  layer2_2x,
  location2x,
  logo2x,
  mail2x,
  mission2x,
  newLogo,
  partner2x,
  services2x,
  SFUI,
  smartphone2x,
  term2x,
  vision2x,
} from "./components/assets";
import RouterComponent from "./components/router";
import { Route, Switch, HashRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from "../src/constants/ga4";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  ReactGA.initialize(GA_MEASUREMENT_ID);

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });

    await Promise.all(promises);
    setIsLoading(false);
  };

  useEffect(() => {
    const imgs = [
      logo2x,
      newLogo,
      smartphone2x,
      services2x,
      about2x,
      layer2_2x,
      mission2x,
      vision2x,
      group237_2x,
      group75_2x,
      contact2x,
      mail2x,
      location2x,
      partner2x,
      term2x,
      keepCalm,
      SFUI,
      karaThumbnail,
      karaImg1,
      karaImg2,
      homeBackground,
    ];
    cacheImages(imgs);
  }, []);
  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <HashRouter>
          <RouterComponent />
        </HashRouter>
      )}
    </div>
  );
}

export default App;
