import { useState, useEffect } from "react";
import client from "../../api/client";
import { GetActiveArtikel, GetSingleArtikel } from "../../graphql/news/queries";

const useNewsApi = () => {
  const [dataActiveNews, setDataActiveNews] = useState([]);
  const [dataSingleNews, setDataSingleNews] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeNews, setActiveNews] = useState({
    active: false,
    content: null,
  });

  useEffect(() => {
    const handleActiveNews = async () => {
      try {
        setLoading(true);
        setError(null);
        const res = await client.query({
          query: GetActiveArtikel,
        });
        const { ListArtikel: data, response } = res.data.GetActiveArtikel;
        if (response.status !== "0") throw new Error(response.errMsg);

        const sortedData = [...data].reverse();
        setDataActiveNews(sortedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    handleActiveNews();
  }, []);

  useEffect(() => {
    const handleSingleNews = async () => {
      try {
        const res = await client.query({
          query: GetSingleArtikel,
          variables: {
            id: activeNews.content,
          },
        });

        const { ListArtikel: data, response } = res.data.GetSingleArtikel;

        if (response.status !== "0") throw new Error(response.errMsg);

        setDataSingleNews(data[0]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (activeNews.content) handleSingleNews();
  }, [activeNews.content]);

  return {
    dataActiveNews,
    setDataActiveNews,
    dataSingleNews,
    setDataSingleNews,
    loading,
    setLoading,
    error,
    setError,
    activeNews,
    setActiveNews,
  };
};

export default useNewsApi;
