import React from "react";
import {
  karaImg1,
  karaImg2,
  karaThumbnail,
  liquid,
  news,
} from "../../components/assets";
import { convertMillisToDDMMYYYY } from "../../lib/utils/formatDate";
import { ArrowBack } from "@material-ui/icons";
import useNewsApi from "../../hooks/news/useNewsApi";
import Loader from "../../components/loader";

const News = () => {
  const { dataActiveNews, dataSingleNews, loading, activeNews, setActiveNews } =
    useNewsApi();

  return (
    <>
      {loading && <Loader mode={"content"} />}
      {!loading && (
        <section className="news">
          <div
            className="news__header"
            onClick={() => setActiveNews({ active: false, content: null })}
          >
            <img className="news__icon" src={news} alt="news" />
            <h3 className="text-title news__title-top">News & Events</h3>
          </div>

          {dataActiveNews.length > 0 &&
            !activeNews.active &&
            dataActiveNews.map((data, index) => {
              return (
                <div className="news__content" key={index}>
                  <div className="news__items">
                    <img
                      src={data.fotoArtikel}
                      alt=""
                      className="news__thumbnail"
                    />
                    <div className="news__isi" style={{ fontSize: "1.7rem" }}>
                      <span
                        className="news__isi__title"
                        onClick={() =>
                          setActiveNews({
                            active: true,
                            content: data.id,
                          })
                        }
                      >
                        {data.judulArtikel}
                      </span>
                      <br />
                      <p
                        style={{
                          fontSize: "1.4rem",
                          fontWeight: "lighter",
                          marginTop: "3px",
                        }}
                      >
                        Posted date, {convertMillisToDDMMYYYY(data.createDate)}.
                      </p>{" "}
                      <br />
                      <p
                        className="news__btn"
                        onClick={() =>
                          setActiveNews({
                            active: true,
                            content: data.id,
                          })
                        }
                      >
                        Read More
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}

          {activeNews.active && dataSingleNews && (
            <>
              <div className="news__news-content">
                <div className="news__title">{dataSingleNews.judulArtikel}</div>
                <p
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "lighter",
                    marginTop: "-23px",
                    marginBottom: "2.5rem",
                  }}
                >
                  Posted date,{" "}
                  {convertMillisToDDMMYYYY(dataSingleNews.createDate)}.
                </p>{" "}
                <br />
                <img
                  src={dataSingleNews.fotoArtikel}
                  alt=""
                  className="news__karatop"
                />
                <div>
                  <div
                    className="news__text"
                    dangerouslySetInnerHTML={{
                      __html: dataSingleNews.isiArtikel,
                    }}
                  ></div>
                  <div
                    className="news__back"
                    onClick={() =>
                      setActiveNews({ active: false, content: null })
                    }
                  >
                    <ArrowBack
                      style={{
                        marginRight: "1rem",
                      }}
                    />
                    Kembali
                  </div>
                </div>
              </div>
            </>
          )}

          <img src={liquid} alt="" className="news__liquid" />
        </section>
      )}
    </>
  );
};

export default News;
