import React from "react";
import {
  bsiLogoTransparent,
  bsiLogoWhite,
  isoLogo27001,
  newLogo,
  pseLogo,
} from "../assets";
import { Link } from "react-router-dom";
import { LINK_CERTIFICATE_OF_TRUST } from "../../constants";

const footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__left">
          <div className="footer__logo_container">
            {/* <img
              className="footer__iso__27001"
              src={isoLogo27001}
              alt="ISO 27001"
            /> */}
            <a
              href={LINK_CERTIFICATE_OF_TRUST}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="footer__bsi"
                src={bsiLogoTransparent}
                alt="BSI Logo"
              />
            </a>
          </div>
          <p style={{ marginTop: "2rem" }}>
            &copy; 2016 &ndash; {new Date().getFullYear()} PT Emos Global
            Digital. All Rights Reserved{" "}
          </p>
        </div>
        <Link className="footer__center" to="term-condition">
          Terms & Conditions
        </Link>
        <div className="footer__right">
          <img src={newLogo} className="footer__logo" alt="EMOS LOGO" />
          <h1 className="footer__text">PT EMOS GLOBAL DIGITAL</h1>
          {/* <p>PT Emos Global Digital</p>
            <p>Jl.Pulo Lentut No 12</p>
            <p>Kawasan Industri Pulogadung</p>
            <p>Jakarta Timur - 13920</p> */}
          <p className="footer__text">Jl.Pulo Lentut No 12</p>
          <p className="footer__text">Kawasan Industri Pulogadung</p>
          <p className="footer__text">Jakarta Timur - 13920</p>
          <p className="footer__text">Telp. 021-4609720</p>
        </div>
      </div>
    </footer>
  );
};

export default footer;
