import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { faUser, faBusinessTime } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';

const CareerItem = () => {
    const history = useHistory()
    const handleRouterDetail = () => {
        history.push('/career/1')
    }
    return ( 
        <>
        <div className="karir-item">
            <div className="left">
                <div className='name'>Relationship Officer</div>
                <div className='daerah'>Bandung, Bengkulu, +9 More</div>
            </div>
            <div className="center">
                <div className="group">
                    <FontAwesomeIcon  icon={faUser} className="group__icon" />
                    <div>Fresh Garduate</div>
                </div>
                <div className="group">
                    <FontAwesomeIcon  icon={faBusinessTime} className="group__icon" />
                    <div>IT / Developer</div>
                </div>
            </div>
            <button onClick={()=>handleRouterDetail()}>Lihat Detail</button>
        </div>
        </>
     );
}
 
export default CareerItem;