import React, { useEffect } from 'react';
import { vision2x, mission2x, layer2_2x, about2x, phone2, liquid } from '../../components/assets';


const About = ()=>{
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    return (
      <section className="about">
        <img className="about__layer" alt="layer" src={layer2_2x} />
        <div className="about__content">
          <div className="about__us">
            <h1 className="about__header text-title">About Us</h1>
            <br />
            <p className="about__text">
              Since its launch in 2016, EMOS has been used by various sales
              channels including pharmacies, hospitals, clinics, drug stores,
              mini markets, milk shops, baby goods stores and grocery stores
              throughout Indonesia.
            </p>
            <br />
            <br />
            <p className="about__text">
              PT EMOS GLOBAL DIGITAL already has EMOS which is prepared to
              develop a digital platform for inter-business health product
              distribution (B2B), especially starting with a network of
              pharmacies throughout Indonesia.
            </p>
            <br />
            <br />
            <p className="about__text">
              With this application, it is hoped that health distribution
              services can be faster and more efficient with good quality.
            </p>
            <br />
            <br />
            <p className="about__text">
              In the future, this digital platform will become a supply chain
              ecosystem for health products that can be utilized by all business
              actors in the health industry.
            </p>
          </div>
          <div className="about__vision">
            <img src={vision2x} alt="telescope" className="about__icon" />
            <h1
              className="about__header text-title"
              style={{ marginBottom: "-1rem" }}
            >
              Our Vision
            </h1>
            <p className="about__text about--text">
              Building a digital ecosystem that provides quality health
              products and services.
            </p>
          </div>
          <div className="about__mission">
            <img src={mission2x} alt="mission" className="about__icon " />
            <h1
              className="about__header text-title"
              style={{ marginBottom: "-.5rem" }}
            >
              Our Mission
            </h1>
            <p className="about__text about--text">
              Become the first choice online B2B application that provide
              pharmaceutical, nutritional and consumer health products
              with excellent services.
            </p>
          </div>
          <div className="about__policy">
            <h1 className="about__header text-title">
              Information Security Management System Policy
            </h1>
            <br />
            <p className='about__text about--text'>
              PT Emos Global Digital is committed to:<br /><br />1. Fulfilling stakeholder
              expectations by realizing confidentiality, integrity, and
              availability of information through the implementation of ISO/IEC
              27001:2022<br /><br />2. Always comply with all rules and regulations related
              to information security that apply in the territory of the
              Republic of Indonesia and the area where work is carried out.<br /><br />3.
              Increased irrational improvements to the performance of the
              Information Security Management System
            </p>
          </div>
        </div>
        <img src={phone2} alt="about emos" className="about__phone" />
        <img src={liquid} alt="about emos" className="about__liquid" />
      </section>
    );
}

export default About;