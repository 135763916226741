import { pad } from "./helper";

const dayArrayID = [
  "Minggu",
  "Senin",
  "Selasa",
  "Rabu",
  "Kamis",
  "Jumat",
  "Sabtu",
];

const monthArrayID = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

// ISOStringDate: string
export const formatISOStringDate = (ISOStringDate) => {
  const arr = ISOStringDate.split("T");
  const time = arr[1].split(".")[0];
  return `${arr[0]} ${time}`;
};

// date: Date
export const convertJSDateTypeToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);

  return `${year}-${month}-${day}`;
};

// date: Date
export const convertJSDateTypeToFilenameDate = (date) => {
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);
  const hour = pad(date.getHours(), 2);
  const min = pad(date.getMinutes(), 2);

  return `${day}${month}${year}`;
};

// date: Date
export const convertJSDateTypeToFilenameDateTimestamp = (date) => {
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);
  const hour = pad(date.getHours(), 2);
  const min = pad(date.getMinutes(), 2);

  return `${day}${month}${year}_${hour}_${min}`;
};

// date: Date
export const convertJSDateTypeToTimestampDate = (date) => {
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);
  const hour = pad(date.getHours(), 2);
  const min = pad(date.getMinutes(), 2);

  return `${year}-${month}-${day} ${hour}:${min}`;
};

export const getCurrentMonth = () => {
  const date = new Date();
  const month = pad(date.getMonth() + 1, 2);
  const year = date.getFullYear();
  return `${year}-${month}`;
};

// monthStripYear: string
export const getFirstDateAndLastDateOfSelectedMonth = (monthStripYear) => {
  const date = new Date(monthStripYear);
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return {
    firstDateString: convertJSDateTypeToYYYYMMDD(firstDay),
    lastDateString: convertJSDateTypeToYYYYMMDD(lastDay),
  };
};

export const getLastMonthString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const lastMonth = month - 1;

  return `${year}-${pad(lastMonth, 2)}`;
};

export const getCurrentMonthString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return `${year}-${pad(month, 2)}`;
};

// date: Date
export const getMonthFromDate = (date) => {
  const month = pad(date.getMonth() + 1, 2);
  const year = date.getFullYear();
  return `${year}-${month}`;
};

const getIntMillis = (millis) => {
  let intMillis;
  if (typeof millis === "string") intMillis = parseInt(millis);
  else intMillis = millis;

  if (isNaN(intMillis)) return null;
  return intMillis;
};

// millis: string | number
const convertMillisToDateComponents = (millis) => {
  const intMillis = getIntMillis(millis);
  if (!intMillis) return;

  const date = new Date(intMillis);

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1, 2);
  const day = pad(date.getDate(), 2);

  const hour = pad(date.getHours(), 2);
  const min = pad(date.getMinutes(), 2);
  const sec = pad(date.getSeconds(), 2);

  const dayId = dayArrayID[date.getDay()];
  const monthId = monthArrayID[date.getMonth()];
  const monthEn = months[date.getMonth()];

  return { year, month, day, hour, min, sec, monthId, dayId, monthEn };
};

export const convertMillisToDDMMYYYY = (millis) => {
  const intMillis = getIntMillis(millis);
  if (!intMillis) return "-";

  const { day, monthId, year } = convertMillisToDateComponents(intMillis);
  return `${day} ${monthId} ${year}`;
};

// millis: string | number
export const convertMillisToYYYYMMDD = (millis) => {
  const intMillis = getIntMillis(millis);
  if (!intMillis) return "-";

  const { day, month, year } = convertMillisToDateComponents(intMillis);
  return `${year}-${month}-${day}`;
};

// millis: string | number
export const formatMillisToDDMMMYYYYhhmmss = (millis) => {
  const intMillis = getIntMillis(millis);
  if (!intMillis) return "-";

  const { day, month, year, hour, min, sec } =
    convertMillisToDateComponents(intMillis);
  return `${day}-${month}-${year} ${hour}:${min}:${sec}`;
};

// millis: string | number
export const formatMillisToDate = (millis) => {
  let intMillis;
  if (typeof millis === "string") intMillis = parseInt(millis);
  else intMillis = millis;

  const date = new Date(intMillis);

  return convertJSDateTypeToTimestampDate(date);
};

// millis: string | number
export const formatMillisToDayDateID = (millis) => {
  const intMillis = getIntMillis(millis);
  if (!intMillis) return "-";

  const { dayId, day, month, year } = convertMillisToDateComponents(intMillis);
  return `${dayId}, ${day} ${month} ${year}`;
};

// millis: string | number
export const formatMillisToTime = (millis) => {
  const intMillis = getIntMillis(millis);
  if (!intMillis) return "-";

  const { hour, min, sec } = convertMillisToDateComponents(intMillis);
  return `${hour}:${min}:${sec}`;
};

// millis: string | number
export const convertMillisToEmosFormat = (millis) => {
  const intMillis = getIntMillis(millis);
  if (!intMillis) return "-";

  const { day, monthEn, year, hour, min } =
    convertMillisToDateComponents(intMillis);
  return `${day}-${monthEn}-${year} ${hour}:${min}`;
};
